<template>

      <div class="container">
        <!-- Breadcrumb End-->
        <div class="row mt-3 mb-3">
          <!--Middle Part Start-->
          <div class="col-lg-3"></div>
          <div id="content" class="col-sm-12 col-lg-5">
            <div class="custom-box" style="height:auto;">
              <h2 class="title">Account Register</h2>
              <form @submit.prevent="register">
                <div class="form-group mb-2">
                  <label class="form-label" for="name">Name</label>
                  <input
                    type="text"
                    name="Name"
                    placeholder="Ex: Mohammad"
                    class="form-control"
                    v-model="form.name"
                    :class="{ 'is-invalid': form.errors.has('name') }"
                  />
                  <has-error :form="form" field="name"></has-error>
                </div>
                <div class="form-group mb-2">
                  <label class="form-label" for="mobile_no">Mobile No</label>
                  <input
                    type="text"
                    name="mobile_no"
                    placeholder="01xxx-xxxxx"
                    class="form-control"
                    maxlength="11"
                    :class="{ 'is-invalid': form.errors.has('mobile_no') }"
                    v-model="form.mobile_no"
                  />
                  <has-error :form="form" field="mobile_no"></has-error>
                </div>

                <div class="form-group mb-2">
                  <label class="form-label">Password</label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    class="form-control"
                    required
                    :class="{ 'is-invalid': form.errors.has('password') }"
                    v-model="form.password"
                  />
                  <has-error :form="form" field="password"></has-error>

                </div>

                <div class="form-group text-center">
                   <button :disabled="form.busy" class="btn btn-sm btn-block btn-primary" type="submit">
                       <i class="fa fa-spinner fa-spin" v-if="form.busy"></i>REGISTER
                   </button>
                </div>
              </form>
              <hr/>
              <router-link :to="{name:'login'}" class="registerNew">
                Already have an account? <strong>Sign in</strong>
              </router-link>

            </div>
          </div>
        </div>
      </div>

</template>
<script>

import Vue from "vue";
import "vue-loading-overlay/dist/vue-loading.css";
import { Form, HasError } from "vform";
import ObjectToFD from 'vue-object-to-formdata';
Vue.use(ObjectToFD);

export default {
  data() {
    return {
      form: new Form({
        name: "",
        mobile_no: "",
        password: "",
      }),
      pre_route: null,
      isLoading: true,
      fullPage: true,
    };
  },
  methods: {
    async register() {
     await  this.form
        .post("/userToRegister",this.$objectToFD(this.form))
        .then((resp) => {
         // console.log(resp);
          if (resp.data.status == "SUCCESS") {
            this.$toast.success(resp.data.message);
            localStorage.setItem("user_token", resp.data.token);
            this.$store.dispatch("user");
            if (this.pre_route == "Checkout") {
              this.$router.push({ name: "Checkout" });
            } else {
              this.$router.push({ name: "Home" });
            }
          }
        })

        .catch(error =>{
          this.$toast.error(error.response.data.message);
        })

    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.pre_route = from.name;
    });
  },
  components:{
    'has-error':HasError,
  }
};
</script>